import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import Layout from "../layouts/layout";
import { RichText } from "prismic-reactjs";
import SEO from "../components/Seo";
import tw from "twin.macro";
import { htmlSerializer } from "../utils/htmlSerializer";
import { linkResolver } from "@prismicio/gatsby-source-prismic-graphql";
import Header from "../components/Header.js";
import { H1 } from "../utils/helpers.js";

const PageWrapper = tw.section`
  px-4 md:px-8 my-8 max-w-3xl mx-auto
`;

const StaticPage = ({ data }) => {
  const page = get(data, "prismic.allStatic_pages.edges[0].node");
  if (!page) return null;
  return (
    <Layout>
      <SEO title={RichText.asText(page.page_title)} />
      <Header bgImageFluid={page.header_imageSharp.childImageSharp.fluid}>
        <H1>{RichText.asText(page.page_title)}</H1>
      </Header>
      <PageWrapper>
        <RichText
          render={page.page_body}
          htmlSerializer={htmlSerializer}
          linkResolver={linkResolver}
        />
      </PageWrapper>
    </Layout>
  );
};

export default StaticPage;

export const query = graphql`
  query StaticPageQuery($uid: String!) {
    prismic {
      allStatic_pages(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            page_title
            header_image
            header_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            page_body
          }
        }
      }
    }
  }
`;
